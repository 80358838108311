import React from 'react';
import MetalmaxContainerBody from './style';

const MetalmaxContainer = ({ children }) => (
  <MetalmaxContainerBody>
    {children}
  </MetalmaxContainerBody>
);

export default MetalmaxContainer;
