import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import MetalmaxHeaderBody from './style';

type Params = {
  logo: string,
}

const MetalmaxHeader = ({
  logo,
}: Params) => {
  const navButton = useRef(null);
  const [open, toggleOpen] = useState(false);

  const getClickParenting = (e: any) => {
    if (e.target !== navButton.current) toggleOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', getClickParenting, false);

    return () => {
      document.removeEventListener('click', getClickParenting);
    };
  }, []);

  return (
    <MetalmaxHeaderBody>
      <MetalmaxHeaderBody.Container>
        <MetalmaxHeaderBody.Logo
          as={Link}
          href="/"
          passHref
        >
          <h1>
            <Image src={logo} width="262" height="48" />
          </h1>
        </MetalmaxHeaderBody.Logo>

        <MetalmaxHeaderBody.Button
          type="button"
          onClick={() => toggleOpen(!open)}
          ref={navButton}
          aria-label={open ? 'Abrir menu' : 'Fechar menu'}
          open={open}
        >
          {(open)
            ? (
              <svg width="60" height="60">
                <path d="M20,21 l19,19 l1,-1 l-19,-19 l-1,1 Z" />
                <path d="M40,21 l-1,-1 l-19,19 l1,1 l19,-19 Z" />
              </svg>
            ) : (
              <svg width="60" height="60">
                <rect width="22" height="2" x="19" y="22" />
                <rect width="22" height="2" x="19" y="29" />
                <rect width="22" height="2" x="19" y="36" />
              </svg>
            )}
        </MetalmaxHeaderBody.Button>
  
        <MetalmaxHeaderBody.Nav open={open}>
          <MetalmaxHeaderBody.Menu>
            <li>
              <Link href="/" passHref>
                Página inicial
              </Link>
            </li>
            <li>
              <Link href="/produtos" passHref>
                Produtos
              </Link>
            </li>
            <li>
              <Link href="/sobre" passHref>
                Sobre a empresa
              </Link>
            </li>
            <li>
              <Link href="/contato" passHref>
                Contato
              </Link>
            </li>
          </MetalmaxHeaderBody.Menu>
        </MetalmaxHeaderBody.Nav>
      </MetalmaxHeaderBody.Container>
    </MetalmaxHeaderBody>
  );
}

export default MetalmaxHeader;