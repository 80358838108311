// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";
import { Integrations } from "@sentry/tracing";

import {
  defaultDenyUrls,
  defaultIgnoreErrors,
  defaultTracesSampleRate,
} from "../../config/Sentry";

const SENTRY_DSN =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_AUTH_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://9a4322a58f0946bf8e372c91e700c44c@o210433.ingest.sentry.io/4503904086654976",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: defaultTracesSampleRate,

  // Activate browser tracing
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NEXT_PUBLIC_ENV_NAME,
  ignoreErrors: defaultIgnoreErrors,
  denyUrls: defaultDenyUrls,
  autoInstrumentServerFunctions: false
});
