const defaultSentryConfig = {
  // Additional config options for the Sentry Webpack plugin. Keep in mind that
  // the following options are set automatically, and overriding them is not
  // recommended:
  //   release, url, org, project, authToken, configFile, stripPrefix,
  //   urlPrefix, include, ignore

  hideSourceMaps: true,
  silent: true, // Suppresses all logs
  // For all available options, see:
  // https://github.com/getsentry/sentry-webpack-plugin#options.
};

const defaultTracesSampleRate =
  process.env.NEXT_PUBLIC_ENV_NAME === "production" ? 0.25 : 1.0;

const defaultIgnoreErrors = [
  // Random plugins/extensions
  "top.GLOBALS",
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  "originalCreateNotification",
  "canvas.contentDocument",
  "MyApp_RemoveAllHighlights",
  "http://tt.epicplay.com",
  "Can't find variable: ZiteReader",
  "jigsaw is not defined",
  "ComboSearch is not defined",
  "http://loading.retry.widdit.com/",
  "atomicFindClose",
  // Facebook borked
  "fb_xd_fragment",
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  "bmi_SafeAddOnload",
  "EBCallBackMessageReceived",
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  "conduitPage",
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  "Script error.",
];

const defaultDenyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];
