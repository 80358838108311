import styled from 'styled-components';

const MetalmaxHeaderBody: any = styled.header`
  background: #E5E5E5;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

MetalmaxHeaderBody.Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

MetalmaxHeaderBody.Logo = styled.a`
  display: block;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    width: 200px;
  }
`;

MetalmaxHeaderBody.Nav = styled.nav<{ open: boolean }>`
  margin: 0;
  padding: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    display: ${({ open }) => (open ? 'block' : 'none')};
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
  }
`;

MetalmaxHeaderBody.Menu = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 16px;

  a {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #04F;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    flex-direction: column;
    gap: 0;
    padding: 5px 0;

    a {
      padding: 5px 10px;
    }
  }
`;

MetalmaxHeaderBody.Button = styled.button<{ open: boolean }>`
  display: none;
  background: none;
  padding: 0;
  margin: 0 0 0 auto;
  border: none;
  cursor: pointer;

  svg {
    display: block;
    pointer-events: none;
    fill: ${({ open }) => (open ? '#AAA' : '#333')};
    transition: fill 0.5s;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    display: block;
  }
`;

export default MetalmaxHeaderBody;