import styled from 'styled-components';

const MetalmaxFooterBody: any = styled.footer`
  background: #E5E5E5;
  padding: 40px 20px;
  margin-top: auto;
`;

MetalmaxFooterBody.Container = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    flex-direction: column;
  }
`;

MetalmaxFooterBody.Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 400px;
`;

MetalmaxFooterBody.Columns = styled.div`
  display: flex;
  gap: 16px;

  div {
    flex: 0 0 50%;
  }

  p {
    margin: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    flex-direction: column;
  }
`;

MetalmaxFooterBody.Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  a {
    font-weight: bold;
    text-decoration: none;
    color: #333;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #04F;
    }
  }
`;

MetalmaxFooterBody.List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  a {
    text-decoration: none;
    color: #333;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #04F;
    }
  }
`;

MetalmaxFooterBody.Disclaimer = styled.p`
  font-size: 0.85rem;
  font-weight: bold;
  margin: 0;
`;

MetalmaxFooterBody.Image = styled.div`
  width: 150px;
  height: 142px;

  img {
    display: block;
  }
`;

export default MetalmaxFooterBody;