import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle<{ theme: any }>`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  address {
    font-style: normal;
    margin: 12px 0;
  }

  #metalmaxContent {
    margin: 0 auto;
    width: 100%;
    max-width: 1040px;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .metalmaxDivider {
    height: 0;
    border: none;
    border-top: 1px solid #DDD;
    margin: 60px 0;
  }

  .metalmaxTextBlock {
    margin: 40px 0;
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
  }

  .metalmaxTextBlockTitle {
    width: 240px;
    flex: 0 0 auto;
    color: #999;

    h2,
    .h2 {
      margin: 0;
      font-size: 2.4rem;
      line-height: 2.8rem;
      font-weight: bold;
    }

    h3,
    .h3 {
      margin: 0;
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: normal;
    }

    .small {
      font-size: 1.2rem;
      line-height: 1.4rem;
      margin-bottom: 10px;
    }
  }

  .metalmaxTextBlockContent {
    flex: 0 1 auto;
    width: 100%;
  }

  .metalmaxSmallTitle {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.4rem;
    margin: 0 0 10px;
    color: #999;
  }

  .metalmaxFeaturesList {
    list-style: none;
    margin: 30px 0;
    padding: 0;
    display: grid;
    grid-template: auto / repeat(2, 1fr);
    gap: 20px;

    li {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      line-height: 1.4rem;
      gap: 16px;

      .icon {
        flex: 0 0 auto;
      }
    }

    &.three-columns {
      grid-template: auto / repeat(3, 1fr);

      li.two-thirds {
        grid-column: span 2;
      }
    }
  }

  .metalmaxButton {
    background: #04F;
    display: block;
    padding: 8px 12px;
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
    font-family: var(--font-family-default);
    text-decoration: none;
    border: none;
    border-radius: 4px;
    line-height: 1.4rem;
    width: max-content;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #28F;
      transform: translateY(-2px);
      box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    }
  }

  p {
    text-align: justify;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm.max}) {
    .metalmaxTextBlock {
      flex-direction: column;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    .metalmaxFeaturesList, {
    .metalmaxFeaturesList.three-columns {
      grid-template: auto / repeat(2, 1fr);

      li.two-thirds {
        grid-column: auto;
      }
    }
  }
`;

export default GlobalStyle;