import React from 'react';
import { ThemeProvider } from 'styled-components';
import Head from 'next/head';
import MetalmaxHeader from 'property-base/src/components/MetalmaxHeader';
import MetalmaxFooter from 'property-base/src/components/MetalmaxFooter';
import MetalmaxContainer from 'property-base/src/components/MetalmaxContainer';
import Theme from 'elementar-ui/dist/Theme';
import GlobalStyle from '../styles/global';
import '../fonts/fonts.css';

const App = ({ Component, pageProps }: any) => {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <title>Metalmax Ligas Especiais</title>
        <link rel="canonical" href="https://metalmax.com.br" />
        <meta name="description" content="Metalmax Ligas Especiais. Produtos pré-fabricados ou sob encomenda de ligas especiais de cobre para indústria." />
        <meta name="keywords" content="Metalmax, ligas especiais, cobre, ligas de cobre" />
        <meta httpEquiv="Content-Language" content="pt-br" />

        {
          <script async dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`,
          }}>
          </script>
        }
      </Head>

      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <MetalmaxContainer>
          <MetalmaxHeader logo="/media/logo.svg" />

          <div id="metalmaxContent">
            <Component {...pageProps} />
          </div>

          <MetalmaxFooter logo="/media/sgs-iso.png" />
        </MetalmaxContainer>
      </ThemeProvider>
    </>
  )
}

export default App;