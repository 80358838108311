import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import MetalmaxFooterBody from './style';

type Params = {
  logo: string,
}

const MetalmaxFooter = ({
  logo
}: Params) => (
  <MetalmaxFooterBody>
    <MetalmaxFooterBody.Container>
      <MetalmaxFooterBody.Content>
        <MetalmaxFooterBody.Columns>
          <div>
            <nav>
              <MetalmaxFooterBody.Menu>
                <li>
                  <Link href="/" passHref>
                    Página inicial
                  </Link>
                </li>
                <li>
                  <Link href="/produtos" passHref>
                    Produtos
                  </Link>
                </li>
                <li>
                  <Link href="/sobre" passHref>
                    Sobre a empresa
                  </Link>
                </li>
                <li>
                  <Link href="/contato" passHref>
                    Contato
                  </Link>
                </li>
              </MetalmaxFooterBody.Menu>
            </nav>
          </div>

          <div>
            <p><strong>Fone/Fax:</strong></p>
            <MetalmaxFooterBody.List>
              <li>(11) 4715-2296</li>
              <li>(11) 4715-2184</li>
              <li>(11) 4715-1396</li>
            </MetalmaxFooterBody.List>
          </div>
        </MetalmaxFooterBody.Columns>

        <MetalmaxFooterBody.Disclaimer>Metalmax. Todos os direitos reservados.</MetalmaxFooterBody.Disclaimer>
      </MetalmaxFooterBody.Content>

      <MetalmaxFooterBody.Image>
        <Image src={logo} width="150" height="142" />
      </MetalmaxFooterBody.Image>
    </MetalmaxFooterBody.Container>
  </MetalmaxFooterBody>
);

export default MetalmaxFooter;